.sideBarButton{
    padding: 15px;
    border-bottom: 2px solid rgb(240, 240, 240);
    cursor: default;
    font-size: 18px;
}
.sideBarButton:hover{
    background-color: rgb(240,240,240);
}
.sideBarButton.active{
    background-color: rgb(53, 146, 253);
    color: white;
}